import Lenis from '@studio-freight/lenis';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin)


const lenis = new Lenis({
    lerp: 0.2,
    duration: 1.5,
    wheelMultiplier: 2,
})

// lenis.on('scroll', (e) => {
//     // console.log(e)
// })


const moveToTop = document.querySelector('.move__to_top');

if (moveToTop) {
    moveToTop.addEventListener('click', () => {
        lenis.scrollTo(0);
    });
}


const titleLines = document.querySelectorAll('.appear_title_line')

titleLines.forEach((item) => {
    ScrollTrigger.create({
        trigger: item,
        start: "top 80%",
        onEnter: (self) => {
            item.classList.add('active')
        },
    });
})

const menuTriggerElm = document.querySelector('.menu__trigger');
const headerLeftLinksElm = document.querySelector('.header__left_links');
const headerLinksElm = document.querySelector('.header__links');
const firstSectionElm = document.querySelector('#home');


ScrollTrigger.create({
    trigger: firstSectionElm,
    start: "top top",
    onUpdate: updateValues,
});

function updateValues() {
    if (ScrollTrigger.isInViewport(firstSectionElm)) {
        if(ScrollTrigger.positionInViewport(firstSectionElm, "bottom") < 0.25) {
            headerLeftLinksElm.classList.add('difference');
            menuTriggerElm.classList.add('difference');
        } else {
            headerLeftLinksElm.classList.remove('difference');
            menuTriggerElm.classList.remove('difference');
        }
        if(ScrollTrigger.positionInViewport(firstSectionElm, "bottom") < 0.5) {
            headerLinksElm.classList.add('difference');
        } else {
            headerLinksElm.classList.remove('difference');
        }
    }
}


// Draw SVG Line

const pathSvgElm = document.querySelector("#path");

if (pathSvgElm) {
    let svgLength = pathSvgElm.getTotalLength();

    pathSvgElm.style.strokeDasharray = svgLength;
    pathSvgElm.style.strokeDashoffset = svgLength;

    let tl = gsap.timeline();

    ScrollTrigger.create({
        trigger: '#path',
        start: "top 80%",
        onUpdate: (self) => {

            let startPos = self.start;
            let endPos = self.end;
            let currentPos = lenis.actualScroll;

            let scrollPercent = (currentPos - startPos) / (endPos - startPos);
            let draw = svgLength * scrollPercent * 1.1;

            pathSvgElm.style.strokeDashoffset = svgLength - draw;

        },
        // markers: true,
    });
}
// Scale Images

const sectionImages = gsap.utils.toArray(".section__image picture");

sectionImages.forEach(function(section, index){
    gsap.to(section, {
        scale: 1.25,
        scrollTrigger: {
            trigger: section,
            start: "top 40%",
            end: "+=2000",
            // markers: true,
            // pin:true,
            scrub: true
        }
    })

})

const sectionListImages = gsap.utils.toArray(".section__list_image");
sectionListImages.forEach(function(section, index){
    const img = section.querySelector('picture');
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: section,
            scrub: true,
            pin: false,
        }
    });
    tl.fromTo(img, {
        yPercent: -10,
        ease: 'none'
    }, {
        yPercent: 10,
        ease: 'none'
    });
})

gsap.to('.section__logo', {
    scale: 1.75,
    scrollTrigger: {
        trigger: '.section__logo',
        start: "top 40%",
        end: "+=1000",
        // markers: true,
        // pin:true,
        scrub: true
    }
})


const imgFlowers = gsap.utils.toArray(".flower_img");
imgFlowers.forEach(function(flower, index){
    const bee = flower.querySelector('.bee');
    const flower_svg = flower.querySelector('.flower');
    const svg = flower.querySelector('svg');

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: flower,
            scrub: true,
            pin: false,
        }
    });
    // tl.fromTo(bee, {
    //     yPercent: -10,
    //     ease: 'none'
    // }, {
    //     yPercent: 10,
    //     ease: 'none'
    // });
    // tl.fromTo(flower_svg, {
    //     yPercent: -15,
    //     ease: 'none'
    // }, {
    //     yPercent: 15,
    //     ease: 'none'
    // });
    tl.fromTo(svg, {
        yPercent: -20,
        ease: 'none'
    }, {
        yPercent: 20,
        ease: 'none'
    });
})



lenis.on('scroll', ScrollTrigger.update)

function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
}

requestAnimationFrame(raf)



// const isTouchDevice = 'ontouchstart' in window;
//
// const createCursorFollower = () => {
//     const $el = document.querySelector('.cursor-follower');
//
//     // Each time the mouse coordinates are updated,
//     // we need to pass the values to gsap in order
//     // to animate the element
//     window.addEventListener('mousemove', (e) => {
//         const { target, x, y } = e;
//         // Check if target is inside a link or button
//         const isTargetLinkOrBtn = target?.closest('.gallery__swiper');
//         // GSAP config
//         gsap.to($el, {
//             x: x + 3,
//             y: y + 3,
//             duration: 0.7,
//             ease: 'power4', // More easing options here: https://gsap.com/docs/v3/Eases/
//             opacity: 1,
//             // transform: `scale(${isTargetLinkOrBtn ? 4 : 1})`,
//             onStart: () => {
//                 // Add a class to the cursor element when the mouse
//                 // is over a link or a button
//                 if (isTargetLinkOrBtn) {
//                     $el.classList.add('is-active');
//                 } else {
//                     $el.classList.remove('is-active');
//                 }
//             },
//         });
//     });
//
//     // Hidding the cursor element when the mouse cursor
//     // is moved out of the page
//     document.addEventListener('mouseleave', (e) => {
//         gsap.to($el, {
//             duration: 0.7,
//             opacity: 0,
//         });
//     });
//
// };
//
// // Only invoke the function if isn't a touch device
// if (!isTouchDevice) {
//     createCursorFollower();
// }















const menuInit = ()=> {
    const menuTriggerClick = () => {

        const menuTriggerElm = document.querySelector('.menu__trigger');
        const menuBlockElm = document.querySelector('.header__menu_block');
        const bodyElm = document.querySelector('body');

        menuTriggerElm.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            menuBlockElm.classList.toggle('open');
            menuTriggerElm.classList.toggle('open');
            bodyElm.classList.toggle('menu-open');

        });
    }


    const menuMenuNavigation = () => {
        const headerMenuElm = document.querySelector('.header__menu');
        const menuItemsElm = headerMenuElm.querySelectorAll('a');
        const menuBlockElm = document.querySelector('.header__menu_block');
        const menuTriggerElm = document.querySelector('.menu__trigger');

        menuItemsElm.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();

                const href = item.getAttribute('href');

                // console.log('href', href)

                const targetElm = document.querySelector(href);

                menuBlockElm.classList.remove('open');
                menuTriggerElm.classList.remove('open');


                lenis.scrollTo(targetElm);
            });
        });
    }


    // const headerLinksTrigger = () => {
    //     const headerLinksElm = document.querySelector('.header__links');
    //     const linksTriggerElm = document.querySelector('.links__trigger');
    //
    //     linksTriggerElm.addEventListener('click', (e) => {
    //         e.preventDefault();
    //         e.stopPropagation();
    //
    //         console.log('click')
    //
    //         headerLinksElm.classList.toggle('open');
    //     });
    // }


    menuTriggerClick();
    menuMenuNavigation();
    // headerLinksTrigger();
}

export default menuInit;
